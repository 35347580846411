<template>
  <div>
    <Heading heading="h4" content="Materials" class="mb-4">
      <router-link
        v-if="isEducators"
        slot="button"
        :to="{
          name: 'EducatorClassMaterialsEdit',
          params: { id: $route.params.id }
        }"
      >
        <el-button type="primary" size="mini">
          <i class="fa fa-edit"></i>
        </el-button>
      </router-link>
    </Heading>
    <hr />
    <el-card v-if="subjects">
      <div class="video-box" v-if="subjects.length > 0">
        <div class="edu-play-left">
          <div class="section-card-list">
            <el-collapse accordion v-model="subjectNow">
              <el-collapse-item
                v-for="(subject, subjectIndex) in subjects"
                :key="subjectIndex"
                :name="subjectIndex"
              >
                <template slot="title">
                  <h5
                    :class="
                      subjectIndexNow == subjectIndex
                        ? 'text-success mt-4 mb-4'
                        : ' mt-4 mb-4'
                    "
                  >
                    <span v-show="subjectNow !== subjectIndex">
                      <i class="fas fa-folder"></i>
                    </span>
                    <span v-show="subjectNow === subjectIndex">
                      <i class="fas fa-folder-open"></i>
                    </span>
                    {{ `${subject.name}` }}
                  </h5>
                </template>
                <div class="subject-children">
                  <el-collapse
                    v-model="materialsNow"
                    accordion
                    v-if="subject.materials.length > 0"
                  >
                    <el-collapse-item
                      v-for="(lesson, materialsIndex) in subject.materials"
                      :key="materialsIndex"
                      :name="materialsIndex"
                    >
                      <template slot="title">
                        <div class="pl-4">
                          <h6 class="mt-3 mb-1">
                            <span v-show="materialsNow !== materialsIndex">
                              <i class="fas fa-folder"></i>
                            </span>
                            <span v-show="materialsNow === materialsIndex">
                              <i class="fas fa-folder-open"></i>
                            </span>
                            {{ `${lesson.title}` }}
                          </h6>
                          <div
                            class="mt-1 mb-3"
                            style="line-height: 1.2; opacity: .5; font-size: 90%;word-break: break-all!important;"
                          >
                            {{ lesson.description }}
                          </div>
                        </div>
                      </template>
                      <template v-if="lesson.material_files.length > 0">
                        <div class="pl-4 pt-2">
                          <b>{{ lesson.material_files.length }}</b> Material(s)
                        </div>
                        <div
                          v-for="(file, index) in lesson.material_files"
                          :key="index"
                          @click="
                            selectMaterial(subjectIndex, materialsIndex, index)
                          "
                        >
                          <div
                            :class="
                              subjectIndexNow === subjectIndex &&
                              materialsIndexNow == materialsIndex &&
                              fileIndexNow == index
                                ? 'section-season p-2 active'
                                : 'section-season p-2'
                            "
                            style="display: flex;min-height: 4rem;align-items:center;justify-content:center;"
                          >
                            <div style="flex:1;" class="pl-4">
                              <div class="season-title" style="width: 100%;">
                                <b v-show="file.type === 'file'">
                                  <i class="fas fa-file"></i>
                                  File
                                </b>
                                <b v-show="file.type === 'link'">
                                  <i class="fas fa-link"></i>
                                  Link
                                </b>
                                <b v-show="file.type === 'videos'">
                                  <i class="fas fa-video"></i>
                                  Video
                                </b>
                                <b v-show="file.type === 'exam'">
                                  <i class="fas fa-chart-bar"></i>
                                  Practice
                                </b>
                              </div>
                              <div class="season-info">
                                <b>{{ file.name }}</b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <el-empty v-else description="" :image-size="50">
                      </el-empty>
                    </el-collapse-item>
                  </el-collapse>
                  <el-empty v-else description="" :image-size="100"></el-empty>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <div class="edu-play-right" v-if="file && file.type">
          <div class="ml-4 mt-4">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>
                <b>{{ subjects[subjectIndexNow].name }}</b>
              </el-breadcrumb-item>
              <el-breadcrumb-item>
                <b>
                  {{
                    subjects[subjectIndexNow].materials[materialsIndexNow].title
                  }}
                </b>
              </el-breadcrumb-item>
              <el-breadcrumb-item>
                <span v-show="file.type === 'link'">
                  <i class="fas fa-link"></i>
                  {{ $t("newCourses.Link") }}
                </span>
                <span v-show="file.type === 'file'">
                  <i class="fas fa-file"></i>
                  File
                </span>
                <span v-show="file.type === 'videos'">
                  <i class="fas fa-video"></i>
                  Video
                </span>
                <span v-show="file.type === 'exam'">
                  <i class="fas fa-chart-bar"></i>
                  Practice
                </span>
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div v-show="file.type === 'link'">
            <div class="fileContainer">
              <h4>
                <i class="fas fa-link"></i>
                {{ $t("newCourses.Link") }}
              </h4>
              <hr />
              <el-form label-width="100px">
                <el-form-item label="Link">
                  <b class="text-success">
                    <a :href="file.name">
                      {{ file.name }}
                    </a>
                  </b>
                </el-form-item>
              </el-form>
              <a :href="file.link">
                <el-button type="primary" style="width: 100%;">
                  <i class="fa fa-eye"></i>
                  Preview
                </el-button>
              </a>
            </div>
          </div>
          <div v-show="file.type === 'file'">
            <div class="fileContainer">
              <h4>
                <i class="fas fa-file"></i>
                {{ file.name }}
              </h4>
              <hr />
              <el-form label-width="100px">
                <el-form-item label="Type">
                  <i class="fas fa-file"></i>
                  {{ $t("newCourses.File") }}
                </el-form-item>
                <el-form-item label="File">
                  <b class="text-success">
                    <a :href="file.name">
                      {{ file.name }}
                    </a>
                  </b>
                </el-form-item>
              </el-form>
              <a :href="file.link">
                <el-button type="primary" style="width: 100%;">
                  <i class="fa fa-eye"></i>
                  Preview
                </el-button>
              </a>
            </div>
          </div>
          <div v-show="file.type === 'videos'">
            <div class="fileContainer">
              <h4>
                <i class="fas fa-video"></i>
                Video
              </h4>
              <hr />
              <div class="videoContainer" v-if="file">
                <template>
                  <template v-if="file.link">
                    <iframe
                      v-if="getVideo(file.link)"
                      :src="getVideo(file.link)"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    />
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div v-show="file.type === 'exam'">
            <div>
              <div
                class="fileContainer"
                v-if="
                  file.session_class_assign_test &&
                    file.session_class_assign_test.exam
                "
              >
                <h4>
                  <i class="fas fa-chart-bar"></i>
                  {{ file.name }}
                </h4>
                <hr />
                <el-form label-width="100px">
                  <el-form-item label="Type">
                    <i class="fas fa-chart-bar"></i>
                    Practice
                  </el-form-item>
                  <el-form-item label="Practice">
                    <b class="text-success">
                      {{ file.name }}
                    </b>
                  </el-form-item>
                  <el-form-item label="Questions">
                    <b class="text-success">
                      {{ file.session_class_assign_test.exam.question_count }}
                    </b>
                    Questions
                  </el-form-item>
                </el-form>
                <a
                  :href="
                    `${satIvywayUrl}/ivyway/test/${getPracticeTypeLink(
                      file.session_class_assign_test.exam.category
                    )}/${
                      file.session_class_assign_test.exam.test_id
                    }/start?session_classes_id=${classId}&session_class_assign_test_id=${
                      file.session_class_assign_test_id
                    }&token=${token}`
                  "
                >
                  <el-button type="primary" style="width: 100%;">
                    <i class="fas fa-play"></i>
                    Start a Test
                  </el-button>
                </a>
                <hr />
                <router-link
                  :to="{
                    name: 'ClassPractices',
                    params: { classId: classId },
                    query: {
                      test_type: 'material'
                    }
                  }"
                >
                  <span>
                    查看所有Materials中的練習
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-empty v-else description="No Materials"></el-empty>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import moment from "moment";
import roleMixin from "@/mixins/role";
import Materials from "@/apis/materials";
import courseApi from "@/views/courses/apis/Add.js";

export default {
  components: {},

  mixins: [roleMixin],

  props: [],
  data() {
    return {
      lesson: null,
      activeName: "classInfo",
      subjectNow: 0,
      materialsNow: 0,
      subjects: [{"id":84,"name":"测试","materials":[{"id":160,"material_subject_id":84,"slug":null,"title":"测试","description":"123123","publish_at":"2024-11-27","material_files":[{"id":559,"material_id":160,"order":0,"type":"link","name":"https://www.baidu.com","link":"https://www.baidu.com","extension":null,"session_class_assign_test_id":null,"session_class_assign_test":null}],"session_class_assign_tests":[]}]},{"id":85,"name":"测试2","materials":[]}],
      materialsWithoutSubject: null
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    isEducators() {
      // console.log(this.$route.path);
      return this.$route.path.includes("/educator");
    },
    isStudents() {
      return this.$route.path.includes("/student");
    },
    satIvywayUrl() {
      return process.env.VUE_APP_SAT_IVYWAY_FRONTEND_DOMAIN;
    },
    roleIsTeacherOrAdmin() {
      return this.isRoleAdmin() || this.isRoleTeacher();
    },
    classId() {
      return this.$route.params.classId;
    },
    myTitle() {
      return this.course ? this.course.name : "課程的標題";
    },
    moment() {
      return moment;
    },
    isAfterPremiereDate() {
      return moment(new Date()).isAfter(this.lesson.premiere_date);
    },
    lessonIndex() {
      return this.$route.query.lessonIndex
        ? parseInt(this.$route.query.lessonIndex)
        : 1;
    },
    subjectIndexNow() {
      return Number(this.$route.query.subjectIndexNow) > -1
        ? Number(this.$route.query.subjectIndexNow)
        : 0;
    },
    materialsIndexNow() {
      return Number(this.$route.query.materialsIndexNow) > -1
        ? Number(this.$route.query.materialsIndexNow)
        : 0;
    },
    fileIndexNow() {
      return Number(this.$route.query.fileIndexNow) > -1
        ? Number(this.$route.query.fileIndexNow)
        : null;
    },
    testIndexNow() {
      return Number(this.$route.query.testIndexNow) > -1
        ? Number(this.$route.query.testIndexNow)
        : null;
    },
    file() {
      return this.subjects &&
        this.subjects.length > 0 &&
        this.subjects[this.subjectIndexNow].materials.length > 0
        ? this.subjects[this.subjectIndexNow].materials[this.materialsIndexNow]
          .material_files[this.fileIndexNow]
        : null;
    },
    unitSlug() {
      return this.$route.query.unit_slug;
    }
  },
  watch: {
    subjectIndexNow() {
      this.subjectNow = this.subjectIndexNow;
    },
    materialsIndexNow() {
      this.materialsNow = this.materialsIndexNow;
    }
  },

  async mounted() {
    let lesson = {
      id: 1,
      order: 1,
      title: {
        en: "How to Structure Your TOEFL Speaking Question 2",
        cn: "How to Structure Your TOEFL Speaking Question 2",
        tw: "How to Structure Your TOEFL Speaking Question 2"
      },
      instructor_name: "Jeff",
      description: {
        en:
          "In this video, TOEFL instructor and test expert Jeff explains everything you need to know about TOEFL Speaking Question 2. Learn the structure and strategies of the task, and see Jeff walk through constructing a perfect-score response using a real official question from the test maker!",
        cn:
          "In this video, TOEFL instructor and test expert Jeff explains everything you need to know about TOEFL Speaking Question 2. Learn the structure and strategies of the task, and see Jeff walk through constructing a perfect-score response using a real official question from the test maker!",
        tw:
          "In this video, TOEFL instructor and test expert Jeff explains everything you need to know about TOEFL Speaking Question 2. Learn the structure and strategies of the task, and see Jeff walk through constructing a perfect-score response using a real official question from the test maker!"
      },
      video_url: "https://www.youtube.com/embed/WHMXTXmNQNU",
      preview_video_url: "https://www.youtube.com/embed/uXyLVfH0te0",
      cover:
        "https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/lesson/how_to_structure_your_toefl_speaking_question_2.png",
      preview_cover:
        "https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/lesson/lessonPreviewQuestion2.png",
      label: {
        en: "Speaking",
        cn: "口说",
        tw: "口說"
      },
      premiere_date: "2022-01-14T17:00:00.000000Z",
      created_at: "2022-01-10T10:37:07.000000Z",
      updated_at: "2022-02-06T18:52:27.000000Z",
      default_cover: null
    };
    this.lesson = {
      ...lesson,
      preview_video_url_key: lesson.preview_video_url.replace(
        "https://www.youtube.com/embed/",
        ""
      ),
      video_url_key: lesson.video_url.replace(
        "https://www.youtube.com/embed/",
        ""
      )
    };
    // await this.getSubjects();
    this.subjectNow = this.subjectIndexNow;
    this.materialsNow = this.materialsIndexNow;
    if (this.$route.query.unit_slug) {
      let testIndex = 0;
      this.subjects.forEach((subject, sIndex) => {
        subject.materials.forEach((material, mIndex) => {
          if (material.slug == this.$route.query.unit_slug) {
            this.$router.replace({
              query: {
                subjectIndexNow: sIndex,
                materialsIndexNow: mIndex,
                testIndexNow: testIndex
              }
            });
          }
        });
      });
    }
    // await this.getSessions();
  },

  methods: {
    async getSessions() {
      const { session_class } = await courseApi.fetchSessionClass(
        this.$route.params.classId,
        {
          test_type: this.testType
        }
      );
      this.section = session_class;
      this.students = session_class.student_class_tickets;
    },
    getPracticeTypeLink(category) {
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;
      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;
      if (category >= 15) {
        return "composePractices";
      } else if (category == 11 || category == 12) {
        return "singlePractice";
      } else if (category == 13 || category == 14) {
        return "singlePractice";
      } else {
        return "singlePractice";
      }
    },
    selectMaterial(subjectIndex, materialsIndex, fileIndex) {
      this.$router.replace({
        query: {
          subjectIndexNow: subjectIndex,
          materialsIndexNow: materialsIndex,
          fileIndexNow: fileIndex
        }
      });
    },
    selectTest(subjectIndex, materialsIndex, testIndex) {
      this.$router.replace({
        query: {
          subjectIndexNow: subjectIndex,
          materialsIndexNow: materialsIndex,
          testIndexNow: testIndex
        }
      });
    },
    async getSubjects() {
      const res = await Materials.getSubjects(this.classId);
      this.subjects = res.material_subjects ? res.material_subjects : [];
      this.materialsWithoutSubject = res.materials ? res.materials : [];
    },
    setPage(page) {
      this.$router.push({
        query: {
          lessonIndex: page
        }
      });
    }
  }
};
</script>

<style scoped>
.video-box {
  display: flex;
  /* margin-top: 1rem; */
}
.edu-play-left {
  min-width: 28%;
  max-width: 500px;
  border-right: 1px solid #ebeef5;
  /* max-height: 50rem;
    overflow: scroll; */
}
.edu-play-right {
  box-sizing: border-box;
  padding: 0 0 10px 0;
  flex: 1;
  padding-bottom: 3rem;
  background-color: #fafafa;
}
::v-deep .el-card__body {
  padding-left: 0;
  padding: 0;
}
.videoContainer {
  border: 1px solid #ebeef5;
  border-radius: 1rem;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  overflow: hidden;
}
.fileContainer {
  /* border: 1px solid #ccc; */
  /* border-radius: 1rem; */
  position: relative;
  /* padding-bottom: 56.25%; */
  padding: 1.5rem;
  overflow: auto;
  min-height: 600px;
  /* background-color: #fafafa; */
}

.videoContainer iframe,
.video-container object,
.video-container embed,
.videoContainer img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
::v-deep .el-collapse-item__wrap {
  background-color: #fafafa;
  background-color: #e3e3e3;
}
::v-deep .el-collapse-item__header {
  height: auto;
  padding: 0 1rem;
}
::v-deep .el-collapse-item__header:hover {
  /* background-color: #fafafa; */
  opacity: 0.8;
}
/* ::v-deep .is-active .el-collapse-item__header {
  background-color: #fafafa;
} */
::v-deep .subject-children .el-collapse-item__header {
  background-color: #fafafa;
}
::v-deep .subject-active .el-collapse-item__header {
  background-color: white;
}
::v-deep .el-collapse-item__content {
  padding-bottom: 0;
}

.section-card-list {
  height: 50rem;
  overflow: auto;
}
.section-season {
  position: relative;
  display: flex;
  /* margin-top: 0.5rem; */
  background: white;
  border: 0.5px solid transparent;
  border-radius: 6px;
  font-size: 1rem;
  overflow: visible;
  cursor: pointer;
  margin: 1rem;
}
.season-order {
  width: 2.5rem;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.1rem;
  color: #9499a0;
  text-align: center;
}
.season-title {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #18191c;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* width: 196px; */
}
.season-info {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: #61666d;
  word-break: break-all !important;
}
.active {
  cursor: default;
  border: 1px solid var(--themeColor);
  background: rgba(11, 163, 149, 0.05);
}
.section-season:hover {
  border: 1px solid var(--themeColor);
  background: rgba(11, 163, 149, 0.05);
}
.class-title {
  margin-top: 1rem;
}
.block-list-item-info {
  display: flex;
  width: 10rem;
  box-sizing: border-box;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 1rem;
  line-height: 2rem;
  color: #61666d;
}
.description {
  color: #9499a0;
  white-space: pre-line;
}
.speaker-avatar {
  display: flex;
  flex-direction: column;
  width: 100px;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .video-box {
    display: block;
  }
  .section-card-list {
    min-height: auto;
  }
  .edu-play-right {
    padding-bottom: 0;
  }
  .fileContainer {
    padding: 1rem;
    padding-top: 1.5rem;
    min-height: auto;
  }
}
</style>
