import ApiService from "@/common/api.service";

export default {
  createClass(data) {
    return ApiService.post("/teaching-classes", data);
  },
  getClassDetail(id) {
    return ApiService.query(`/teaching-classes/${id}`);
  },
  updateClass(id, data) {
    return ApiService.patch(`/teaching-classes/${id}`, data);
  }
};
